import { Component, ElementRef, HostListener, Input, ViewChild, Renderer2, OnInit, OnDestroy } from "@angular/core";

@Component({
    selector: "dff-dropdown-button",
    templateUrl: "./dff-dropdown-button.component.html",
    styleUrls: ["./dff-dropdown-button.component.scss"]
})
export class DffDropdownButtonComponent implements OnInit, OnDestroy {
    @Input() public titel = "";
    @Input() public href = "";
    @Input() public svgIcon = "";
    @Input() public class = "";

    @ViewChild("dropdown") public dropdown: ElementRef;

    private listener: Function;

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.listener = this.renderer.listen("document", "focusout", () => {
            if (!this.el.nativeElement.contains(document.activeElement)) {
                this.onFocusOut();
            }
        });
    }

    ngOnDestroy() {
        if (this.listener) {
            console.log("remove listener");
            this.listener();
        }
    }

    @HostListener("keydown.arrowdown", ["$event"]) onArrowDown(event: KeyboardEvent) {
        event.preventDefault();
        if (!this.el.nativeElement.classList.contains("active")) {
            this.el.nativeElement.classList.toggle("active");
            this.toggleAriaExpanded();
        }
        const focusableElementsNodeList = this.el.nativeElement.querySelectorAll("[href]");
        const focusableElementsArray = Array.from(focusableElementsNodeList);
        const focusedElement = document.activeElement;
        const currentIndex = focusableElementsArray.indexOf(focusedElement);
        const nextIndex = (currentIndex + 1) % focusableElementsArray.length;
        const nextElement = focusableElementsArray[nextIndex] as HTMLElement;
        if (nextElement !== null) {
            nextElement.focus();
        }
    }

    @HostListener("keydown.arrowup", ["$event"]) onArrowUp(event: KeyboardEvent) {
        event.preventDefault();
        if (!this.el.nativeElement.classList.contains("active")) {
            this.el.nativeElement.classList.toggle("active");
            this.toggleAriaExpanded();
        }
        const focusableElementsNodeList = this.el.nativeElement.querySelectorAll("[href]");
        const focusableElementsArray = Array.from(focusableElementsNodeList);
        const focusedElement = document.activeElement;
        const currentIndex = focusableElementsArray.indexOf(focusedElement);
        const nextIndex = (currentIndex - 1) % focusableElementsArray.length;
        const prevElement = focusableElementsArray[nextIndex] as HTMLElement;
        if (prevElement !== null) {
            prevElement.focus();
        }
    }

    @HostListener("keydown.enter") onEnter() {
        this.el.nativeElement.classList.toggle("active");
        this.toggleAriaExpanded();
    }

    @HostListener("keydown.space") onSpace() {
        this.el.nativeElement.classList.toggle("active");
        this.toggleAriaExpanded();
    }

    @HostListener("mouseover") onMouseover() {
        this.el.nativeElement.classList.add("active");
    }

    @HostListener("mouseleave") onmouseleave() {
        setTimeout(() => {
            // for a more accesible dropdown, we should add a delay before closing the dropdown when accessing with the mouse
            if (!this.el.nativeElement.contains(document.activeElement)) {
                this.el.nativeElement.classList.remove("active");
            }
        }, 1000);
    }

    public onFocusOut(): void {
        setTimeout(() => {
            if (!this.el.nativeElement.contains(document.activeElement)) {
                this.el.nativeElement.classList.remove("active");
                this.dropdown.nativeElement.setAttribute("aria-expanded", "false");
            }
        }, 0);
    }

    private toggleAriaExpanded(): void {
        const isExpanded = this.dropdown.nativeElement.getAttribute("aria-expanded") === "true";
        this.dropdown.nativeElement.setAttribute("aria-expanded", (!isExpanded).toString());
    }

    public onClick(event: MouseEvent, href: string): void {
        if (!href) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
